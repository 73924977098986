import "./PartnerBanner.css";
import React from "react";

import aigab from "../../assets/AIGAB-logoRGB.png";
import vini from "../../assets/paesaggi_vitivinicoli.png";
import lovelanghe from "../../assets/lovelanghe_shop-t-400x276-0.png";
import { useTranslation } from "react-i18next";

const images = [
  { src: aigab, alt: "Aigab Logo", maxHeight: "30px" },
  { src: vini, alt: "Logo Paesaggi Vitivinicoli", maxHeight: "100px" },
  { src: lovelanghe, alt: "Logo LoveLanghe", maxHeight: "70px" },
];

const PartnerBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="partner-banner-container">
      <h2 className="partner-title">{t("our_partners")}</h2>
      <div className="partner-images-wrapper">
        {/* Mappa l'array e mostra le immagini */}
        {images.map((image, index) => (
          <img
            key={index}
            className="partner-img"
            src={image.src}
            alt={image.alt}
            style={{ maxHeight: image.maxHeight }}
          />
        ))}
      </div>
    </div>
  );
};

export default PartnerBanner;
