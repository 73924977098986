import React, { useState } from "react";
import "./Footer.css";
import { Row, Col } from "react-bootstrap";
import logo from "../../assets/logo.png";
import { FaInstagram, FaXTwitter, FaTiktok, FaLinkedin } from "react-icons/fa6";
import ShareModal from "../share modal/ShareModal";
import AboutUsModal from "../aboutUs modal/AboutUsModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * Footer Component
 *
 * The Footer component displays the footer section of the website. It includes the company logo,
 * social media icons, and navigational links for company information, support, and legal policies.
 *
 * Key Features:
 *
 * 1. **Social Media Links**:
 *    - Displays icons linking to various social media platforms.
 *
 * 2. **Company, Support, and Legal Sections**:
 *    - Contains links to different sections such as About, Contact Us, FAQ, and legal documents.
 *
 * 3. **Modal Handling**:
 *    - Manages the visibility of `ShareModal` and `ContactModal` using state hooks.
 *
 * 4. **Responsive Design**:
 *    - Includes spacing adjustments for different screen sizes to maintain layout consistency.
 */

export default function Footer() {
  const { i18n, t } = useTranslation();
  // URL for sharing content
  const shareUrl = "https://DVC-apartments.com"; // Update this with the actual URL

  const cookiePolicyIds = {
    it: "66572534",
    en: "13359571",
    fr: "16339948",
    de: "17791752",
    es: "44912769",
  };

  const cookiePolicyId =
    cookiePolicyIds[i18n.language.split("-")[0]] || cookiePolicyIds["it"];

  // State hooks to manage the visibility of modals
  const [showReferafriendModal, setShowReferafriendModal] = useState(false);
  const [showAboutUsModal, setShowAboutUsModal] = useState(false);

  return (
    <>
      <Row className="footer">
        <Col xs={12} md={4} className="footer-logo">
          {/* Display company logo and information */}
          <img src={logo} alt="logo DVC" />
          <p style={{ marginBottom: 0 }}>{t("footer.brand")}</p>
          <p style={{ marginBottom: 0 }}>DVC CONSULTING S.R.L. SB</p>
          <p style={{ marginBottom: 0 }}>Piazza Michele Ferrero 4, Alba (CN)</p>
          <p>P.IVA 04114260047</p>
          <div className="footer-icons mt-2 mt-md-4">
            {/* Social media icons with links */}
            <a
              href="https://www.instagram.com/dvcapartments_/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="DVC Apartments on Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/yourTwitterHandle"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="DVC Apartments on Twitter"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://www.tiktok.com/@dvcapartments"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="DVC Apartments on TikTok"
            >
              <FaTiktok />
            </a>
            <a
              href="https://www.linkedin.com/company/dvc-apartments/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="DVC Apartments on LinkedIn"
            >
              <FaLinkedin />
            </a>
          </div>
        </Col>
        <Col xs={12} md={8} className="footer-info mt-5 mt-md-0">
          <Row>
            {/* Company section with navigational links */}
            <Col xs={6} md={4} className="footer-section">
              <h5>{t("footer.company")}</h5>
              <ul>
                <li>
                  <div
                    className="footer-link"
                    onClick={() => setShowAboutUsModal(true)}
                  >
                    {t("about_dvc_title")}
                  </div>
                </li>
                <li>
                  <Link to="/contact" className="footer-link">
                    {t("contact_us")}
                  </Link>
                </li>
              </ul>
            </Col>
            {/* Support section with navigational links */}
            <Col xs={6} md={4} className="footer-section">
              <h5>{t("footer.support")}</h5>
              <ul>
                {/* <li><div className="footer-link">FAQ</div></li> */}
                <li>
                  <div
                    className="footer-link"
                    onClick={() => setShowReferafriendModal(true)}
                  >
                    {t("refer_a_friend")}
                  </div>
                </li>
              </ul>
            </Col>
            {/* Legal section with navigational links */}
            <Col xs={6} md={4} className="footer-section mt-3 mt-md-0">
              <h5>{t("footer.legal")}</h5>
              <ul>
                <li>
                  <a
                    href={`https://www.iubenda.com/privacy-policy/${cookiePolicyId}/cookie-policy`}
                    target="_blank"
                    className="footer-link"
                    rel="noreferrer"
                  >
                    {t("footer.cookie_policy")}
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.iubenda.com/privacy-policy/${cookiePolicyId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    {t("footer.privacy_policy")}
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.iubenda.com/termini-e-condizioni/${cookiePolicyId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    {t("footer.terms_of_service")}
                  </a>
                </li>
                <li>
                  <button className="iubenda-cs-preferences-link footer-link border-0 bg-transparent p-0">
                    {t("footer.privacy_preferences")}
                  </button>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Spacer for small screens to maintain layout */}
      <div className="d-lg-none" style={{ height: "50px" }}></div>
      {/* Modals for sharing and contacting */}
      <ShareModal
        show={showReferafriendModal}
        handleClose={() => setShowReferafriendModal(false)}
        url={shareUrl}
      />
      <AboutUsModal
        show={showAboutUsModal}
        handleClose={() => setShowAboutUsModal(false)}
      />
    </>
  );
}
