// © 2024 Fabio Carucci. All Rights Reserved.
import "./Homepage.css";
import React, { useState, useEffect, useRef } from "react";
import CustomNavbar from "../../components/navbar/CustomNavbar";
import Optionsbar from "../../components/optionsbar/Optionsbar";
import Footer from "../../components/footer/Footer";
import logo from "../../assets/DVC Apartments.webp";
import {
  PiArrowBendRightDownBold,
  PiArrowBendLeftDownBold,
} from "react-icons/pi";
import { FaMapLocationDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useLiveChat } from "../../context/LiveChatContext";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import ReactPixel from "react-facebook-pixel";
import PartnerBanner from "../../components/partner banner/PartnerBanner";

/**
 * Homepage Component
 *
 * The Homepage component renders the main layout for the homepage, including a background video,
 * a title with a logo, a custom navigation bar, an options bar, and a footer. It also manages the
 * visibility and behavior of a "Map View" button based on scroll position and user interaction.
 *
 * Key Features:
 *
 * 1. **Background Video**:
 *    - Displays a looping background video with a title overlay and a button to scroll to the main content.
 *
 * 2. **Custom Navbar**:
 *    - A navigation bar that becomes fixed to the top of the screen when the user scrolls past its initial position.
 *
 * 3. **Options Bar**:
 *    - Provides filtering options for apartments, integrated with the fixed navbar and map view toggle.
 *
 * 4. **Map View Button**:
 *    - A button that toggles between map view and apartment list view, which becomes visible when the navbar is fixed.
 *
 * 5. **Live Chat Integration**:
 *    - Integrates with a live chat context to show or hide the chat feature based on user interaction with the map view.
 *
 * 6. **Responsive Design**:
 *    - Adjusts the position of the map view button based on screen width and toggles its visibility accordingly.
 *
 * State Management:
 * - `navbarFixed`: Determines if the navbar is fixed to the top of the screen.
 * - `showMapViewButton`: Controls the visibility of the "Map View" button.
 * - `isMapView`: Tracks whether the map view is currently active.
 *
 * Refs:
 * - `navbarRef`: Stores a reference to the navbar DOM element.
 * - `videoRef`: Stores a reference to the video container DOM element.
 * - `initialNavbarPosition`: Stores the initial vertical position of the navbar for scroll detection.
 *
 * Effects:
 * - Updates the initial navbar position and sets up scroll and resize event listeners.
 * - Cleans up event listeners when the component unmounts.
 */
export default function Homepage() {
  const [cookies] = useCookies(["_iub_cs-s3824551"]);
  const { showLiveChat, setShowLiveChat } = useLiveChat();
  const { t } = useTranslation();
  // State to manage the fixed position of the navbar and the visibility of the Map View button
  const [navbarFixed, setNavbarFixed] = useState(false);
  const [showMapViewButton, setShowMapViewButton] = useState(false);
  const [isMapView, setIsMapView] = useState(false);

  // Refs to store references to DOM elements and initial positions
  const navbarRef = useRef(null);
  const videoRef = useRef(null);
  const initialNavbarPosition = useRef(0);
  const [navbarHeight, setNavbarHeight] = useState(0);

  // Function to update the initial navbar position and height
  const updateNavbarPosition = () => {
    if (navbarRef.current) {
      initialNavbarPosition.current = navbarRef.current.offsetTop;
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  };

  // Function to smoothly scroll to the content section below the video
  const handleScrollToContent = () => {
    if (videoRef.current) {
      window.scrollTo({
        top: videoRef.current.offsetHeight,
        behavior: "smooth",
      });
    }
  };

  // Function to handle the Map View button click
  const handleMapViewClick = () => {
    if (
      cookies["_iub_cs-s3824551"] &&
      cookies["_iub_cs-s3824551"].purposes[5]
    ) {
      ReactPixel.trackCustom("ViewMap");
    }

    setIsMapView(!isMapView);
    setShowLiveChat(!showLiveChat);
  };

  const handleScroll = () => {
    if (window.scrollY >= initialNavbarPosition.current) {
      setNavbarFixed(true);
      setShowMapViewButton(true); // Show the button when the navbar is fixed
    } else {
      setNavbarFixed(false);
      setShowMapViewButton(false); // Hide the button when the navbar is not fixed
    }
  };

  // useEffect to handle scroll and resize events
  useEffect(() => {
    setShowLiveChat(true);
    // Update the navbar position on component mount
    updateNavbarPosition();

    // Add event listeners for scroll and resize events
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateNavbarPosition);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateNavbarPosition);
    };
  }, []);

  // Determine the bottom position of the Map View button based on screen width and map view state
  const buttonBottom = window.innerWidth < 992 && !isMapView ? "75px" : "20px";

  return (
    <div style={{ height: "fit-content" }}>
      <Helmet>
        <title>DVC Apartments</title>
        <meta
          name="description"
          content="Explore apartments in the Langhe, Roero and Monferrato regions with DVC Apartments. Your perfect holiday home awaits."
        />
        <meta property="og:title" content="DVC Apartments" />
        <meta
          property="og:description"
          content="Discover apartments in the heart of the Langhe, Roero and Monferrato. Book your perfect stay with us!"
        />
        <meta
          property="og:image"
          content="https://dvcapartments.com/og-image.png"
        />
        <meta property="og:url" content="https://dvcapartments.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="DVC Apartments" />
        <meta
          name="twitter:description"
          content="Find your perfect apartment with DVC."
        />
        <meta
          name="twitter:image"
          content="https://dvcapartments.com/og-image.png"
        />
      </Helmet>
      <div className="video-container" ref={videoRef}>
        {/* Background video section */}
        <video
          src="https://cdn.dvcapartments.com/static/DVC-header.mp4"
          controls={false}
          autoPlay
          loop
          muted
          playsInline
        >
          {t("video_not_supported")}
        </video>
        <div className="homepage-title">
          <img src={logo} width="338" alt="logo DVC" />
        </div>
        {/* Button to scroll down to the content */}
        <button
          className="arrow-btn d-none d-lg-flex"
          onClick={handleScrollToContent}
        >
          <PiArrowBendLeftDownBold />
          <span>{t("find_your_houses")}</span>
          <PiArrowBendRightDownBold />
        </button>
      </div>
      {/* Spacer to prevent content jump when navbar becomes fixed */}
      <div
        style={{
          height: navbarFixed ? `calc(${navbarHeight}px + 46px)` : "0px",
        }}
      ></div>
      {/* Custom Navbar component with fixed positioning */}
      <CustomNavbar ref={navbarRef} fixed={navbarFixed} isMapView={isMapView} />
      {/* Options bar component to filter apartments */}
      <Optionsbar fixed={navbarFixed} isMapView={isMapView} />

      {/* Map View button, only visible when the navbar is fixed */}
      {(showMapViewButton || isMapView) && (
        <button
          className="map-view-button"
          onClick={handleMapViewClick}
          style={{ bottom: buttonBottom }}
        >
          <FaMapLocationDot />
          {!isMapView ? `${t("map_view")}` : `${t("apartment_list")}`}
        </button>
      )}
      {!isMapView && <PartnerBanner />}
      {!isMapView && <Footer />}
    </div>
  );
}
